$white: #FFFFFF;
$cyan: #55C6C2;

$dark_grey: #999999;
$light_grey: #F2F2F2;

$header_text_black: #707070;

$chat_window_background: #FAFAFA;
$chat_windwow_text_black: #444444;

$message_agent_background: #7099BC;
$message_user_background: #E6E6E6;

$box_shadow_grey: rgba(0, 0, 0, 0.16);