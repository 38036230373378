html,
body {
  padding: 0;
  margin: 0;
}

* {
  outline: none;
  box-sizing: border-box;
  font-family: Verdana, Geneva, sans-serif;
}

.button {
  &:hover {
    cursor: pointer;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}