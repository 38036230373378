@import "../../../style/colors";

$message_spacing_from_end: 50px;
$message_margin: 10px;
$message_tail_visible_length: 10px;

.Message {
  $line-height: 15px;

  word-break: break-word;
  font-size: 12px;
  line-height: $line-height;
  padding: 12px;
  border-radius: 8px;
  margin: $message_margin;
  margin-top: 0;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    border: 2*$message_tail_visible_length solid transparent;
    border-bottom: 0;
  }

  &-Agent {
    background: $message_agent_background;
    color: $white;
    margin-right: $message_spacing_from_end;

    &:after {
      border-right-color: $message_agent_background;
      border-left: 0;
      left: -$message_tail_visible_length;
    }
  }

  &-User {
    align-self: flex-end;
    background: $message_user_background;
    color: $chat_windwow_text_black;
    margin-left: $message_spacing_from_end;

    &:after {
      border-left-color: $message_user_background;
      border-right: 0;
      right: -$message_tail_visible_length;
    }
  }

  a {
    display: block;
    margin-top: $line-height;
    color: $white;
  }
}

  