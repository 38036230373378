@import "../style/colors";
$dimensions: 70px;

.Bubble {
  background: $cyan;
  box-shadow: 0 3px 6px $box_shadow_grey;
  width: $dimensions;
  height: $dimensions;
  border-radius: $dimensions/2;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 150ms ease-in transform;

  &:hover {
    transform: scale(1.05);
  }
}