@import "./style/colors";
@import "./style/shared";

.Chat-app {
  position: fixed;
  bottom: 30px;
  right: 30px;
}

* {
  scrollbar-width: 12px;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

::-webkit-scrollbar-track {
  background-clip: padding-box;
  border-style: solid;
  border-color: transparent;
  border-image: initial;
  border-width: 0px 0px 0px 4px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  min-height: 28px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 0px inset, rgba(0, 0, 0, 0.07) 0px -1px 0px inset;
  border-style: solid;
  border-color: transparent;
  border-image: initial;
  border-width: 1px 1px 1px 6px;
  padding: 100px 0px 0px;
}

::-webkit-scrollbar {
  height: 16px;
  width: 12px;
  overflow: visible;
}