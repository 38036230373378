.Footer {
  form {
    height: 41px;
    display: flex;

    textarea {
      $font-size: 12px;

      flex: 1;
      resize: none;
      font-size: $font-size;
      line-height: 15px;
      padding: $font-size 0 $font-size 10px;
      border: none;
    }

    .flex-center.send {
      width: 30px;

      svg {
        margin-bottom: 2px;

        &.hide {
          opacity: 0.4;
        }

        &.show {
          opacity: 1;
        }
      }
    }
  }
}