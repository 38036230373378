@import "../../../style/colors";

.Header {
  background: $light_grey;
  position: relative;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  color: $header_text_black;
  padding: 16px;
  padding-left: 10px;

  .closeButton {
    $dimensions: 32px;

    position: absolute;
    top: -$dimensions/2;
    right: -$dimensions/2;
    width: $dimensions;
    height: $dimensions;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $dark_grey;
    border-radius: $dimensions/2;
  }
}