@import "../../../style/colors";

.Chat {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: $chat_window_background;
  padding: 10px;

  .signature {
    font-size: 12px;
    line-height: 10px;
    color: $chat_windwow_text_black;
    opacity: 0.15;
    align-self: center;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
}