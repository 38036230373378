@import "../style/colors";

.ChatWindow {
  max-width: 280px;
  height: 460px;
  background: white;
  box-shadow: 0 3px 6px $box_shadow_grey;
  display: flex;
  flex-direction: column;    
  align-items: stretch;
  justify-content: space-between;
}